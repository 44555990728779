import React from "react";
import { Line } from "@ant-design/charts";

const DualLineChart = ({ data, type, cat1, cat2, Xval, Yval }) => {
  let transformedData = [];

  if (type === "Facebook") {
    transformedData = data.flatMap((item) => [
      { age: item.age, value: parseInt(item.clicks), category: cat1 },
      { age: item.age, value: parseInt(item.link_clicks), category: cat2 },
    ]);
  } else {
    transformedData = data.flatMap((item) => [
      { 
        age: item.dimensionValues[0].value,
        value: parseInt(item.metricValues[0].value),
        category: cat1,
      },
      {
        age: item.dimensionValues[0].value,
        value: parseInt(item.metricValues[1].value),
        category: cat2,
      },
    ]);
  }

  const config = {
    data: transformedData,
    xField: "age",
    yField: "value",
    seriesField: "category",
    color: ({ category }) => {
      if (category === cat1) {
        return "#E1C233"; // Yellow for Amount Spent
      }
      return "#6A0DAD"; // Purple for Leads
    },
    legend: false, // Remove the legend for Amount Spent and Leads
    xAxis: {
      label: {
        formatter: (val) => {
          const date = new Date(val);
          return `${date.getDate()}-${date.toLocaleString("default", {
            month: "short",
          })}-${date.getFullYear()}`;
        },
        style: {
          fill: "#000000",
        },
      },
      title: {
        text: Xval,
        style: { fontWeight: 700, fontSize: 14, },
      },
    },
    yAxis: {
      label: {
        formatter: (value, index, items) => {
          const category = items[index]?.data?.category;
          if (category === cat1) {
            return `₹${value}`; // Add Rupee symbol if category is Amount Spent
          }
          return value;
        },
        style: {
          fill: "#000000",
        },
      },
    },
    label: {
      position: "top", // Position label above each point
      style: {
        fill: (datum) => (datum.category === cat1 ? "#E1C233" : "#6A0DAD"), 
        fontSize: 12,
        fontWeight: "bold",
      },
      formatter: (datum) => {
        if (datum.category === cat1) {
          return `₹ ${datum.value}`; // Display Rupee symbol for Amount Spent
        }
        return datum.value; // Display value directly for Leads
      },
    },
    point: {
      size: 5,
      shape: "circle",
      style: ({ category }) => {
        if (category === cat1) {
          return {
            fill: "#E1C233", // Yellow marker for Amount Spent
            stroke: "#E1C233",
          };
        }
        return {
          fill: "#6A0DAD", // Purple marker for Leads
          stroke: "#6A0DAD",
        };
      },
    },
    tooltip: {
      showMarkers: true,
      customContent: (title, items) => {
        const date = new Date(title);
        const formattedDate = `Date: ${date.getDate().toString().padStart(2, '0')}-${date.toLocaleString("default", {
          month: "short",
        })}-${date.getFullYear()}`;

        return `<div style="text-align: left; padding: 8px 12px; border-radius: 8px; background-color: white;">
        <div style="border-bottom: 1px solid #ccc; font-weight: bold; margin-bottom: 8px; padding-bottom: 4px;">
          ${formattedDate}
        </div>
        ${items
          .map(
            (item) => `<div style="display: flex; align-items: center; gap: 6px; margin-top: 8px;">
              <span style="color: ${item.color}; font-size: 16px;">●</span>
              <span style="font-size: 14px;">${item.name}: ${item.name === "Amount Spent" ? `₹ ${item.value}` : item.value}</span>
            </div>`
          )
          .join("")}
      </div>`;
      },
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
  };

  return (
    <div style={{ width: "95%" }}>
      <Line {...config} />
    </div>
  );
};

export default DualLineChart;

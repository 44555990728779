import { notification } from 'antd'
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { PAGE_HEAD_TITLE } from '../../constants'
import AppContext from '../../context/AppContext'
import { Database } from '../../firebase'
import { Header } from '../../styles/styles'
import '../keywordSearch/style.css'
import AmazonAd from './amazonAd'
import BingAd from './bingAd'
import BingEcomAd from './bingecomAd'
import DisplayAd from './displayAd'
import FacebookAd from './facebook'
import FlipkartAd from './flipkartAd'
import GoogleAd from './googleAd'
import GoogleEcomAd from './googleEcomAd'
import LinkedInAd from './linkedinAd'
import RedditAd from './redditAd'
import YoutubeAd from './youtubeAd'

const YoutubeAds = (props) => {
  const { setLoadingFullScreen } = useContext(AppContext)

  const authUser = props.user
  const searchType = props.searchType
  const [searchData, setSearchData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    document.title = `Collection - Youtube Search Ads ${PAGE_HEAD_TITLE}`
    ;(async function () {
      await fetchCollections()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) //eslint-disable-line

  const fetchCollections = async () => {
    var { uid } = authUser
    if (uid) {
      setIsLoading(true)
      const q = query(
        collection(Database, 'youtubeAdsCollection'),
        where('userId', '==', uid),
        where('searchType', '==', searchType),
      )
      const querySnapshot = await getDocs(q)
      if (querySnapshot) {
        querySnapshot.forEach(async (doc) => {
          var data = await doc.data()
          const dateCreated = moment(data.createdDate.toDate()).format(
            'DD-MM-YYYY',
          )
          data.adsData = JSON.parse(data.adsData)
          const item = { ...data, createdDate: dateCreated, view: doc.id }
          setSearchData((arr) => [...arr, item])
          setIsLoading(false)
        })
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    }
  }

  const handleRemoveFromFav = async (item) => {
    setLoadingFullScreen(true)
    await deleteDoc(doc(Database, 'youtubeAdsCollection', item.view))
    const newData = searchData.filter((i) => i.view !== item.view)
    setSearchData(newData)
    notification['success']({
      message: 'Removed from collection!!',
      placement: 'topRight',
    })
    setLoadingFullScreen(false)
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div>
          <span className="goback" onClick={() => props.handleGoBack()}>
            {' '}
            Go Back
          </span>
        </div>
        <Header>Your Collections</Header>
        {searchData.length ? (
          <div className="row">
            {searchData.map((item2, index) => {
              const item = item2.adsData
              item.view = item2.view
              if (searchType === 'YOUTUBE') {
                return (
                  <YoutubeAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'GOOGLE_ECOM') {
                return (
                  <GoogleEcomAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'GOOGLE_ADS') {
                return (
                  <GoogleAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'DISPLAY_ADS') {
                return (
                  <DisplayAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'FLIPKART_ADS') {
                return (
                  <FlipkartAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'REDDIT_ADS') {
                return (
                  <RedditAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'LINKEDIN_ADS') {
                return (
                  <LinkedInAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'AMAZON_ADS') {
                return (
                  <AmazonAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'FACEBOOK_ADS') {
                return (
                  <FacebookAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'BING_ECOM_AD') {
                return (
                  <BingEcomAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'BING_AD') {
                return (
                  <BingAd
                    item={item}
                    index={index}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              }
              return null
            })}
          </div>
        ) : isLoading ? (
          <h5 className="text-center">Loading..</h5>
        ) : (
          <h5 className="text-center">No items found in collection!!</h5>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(YoutubeAds)

import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";

export const locationOptions = [
  {
    key: "1",
    value: "",
    text: "All Countries",
    country: "",
    countryCode: "",
    currencySymbol: "",
    currencyCode: "",
  },
  {
    key: "2036",
    value: "ausd",
    text: "Australia",
    country: "Australia",
    countryCode: "AU",
    currencySymbol: "$",
    currencyCode: "AUD",
  },
  {
    key: "2124",
    value: "cnd",
    text: "Canada",
    country: "Canada",
    countryCode: "CA",
    currencySymbol: "$",
    currencyCode: "CAD",
  },
  // {
  //     key: '2276',
  //     value: 'geuro',
  //     text: 'Germany Euro €',
  //     country: 'Germany',
  //     countryCode: 'DE',
  //     currencySymbol: '€',
  //     currencyCode: 'EUR',
  // },
  {
    key: "2356",
    value: "inr",
    text: "India",
    country: "India",
    countryCode: "IN",
    currencySymbol: "₹",
    currencyCode: "INR",
  },
  {
    key: "2702",
    value: "sgd",
    text: "Singapore",
    country: "Singapore",
    countryCode: "SG",
    currencySymbol: "$",
    currencyCode: "SGD",
  },
  {
    key: "2784",
    value: "dirham",
    text: "United Arab Emirates",
    country: "United Arab Emirates",
    countryCode: "AE",
    currencySymbol: "د.إ",
    currencyCode: "AED",
  },
  {
    key: "2826",
    value: "pound",
    text: "United Kingdom",
    country: "United Kingdom",
    countryCode: "UK",
    currencySymbol: "£",
    currencyCode: "GBP",
  },
  {
    key: "2840",
    value: "usd",
    text: "United States",
    country: "United States",
    countryCode: "US",
    currencySymbol: "$",
    currencyCode: "USD",
  },
  {
    key: "2458",
    value: "myr",
    text: "Malaysian Ringgit",
    country: "Malaysian Ringgit",
    countryCode: "MY",
    currencySymbol: "RM",
    currencyCode: "MYR",
  },
  {
    key: "2360",
    value: "idr",
    text: "Indonesia",
    country: "Indonesia",
    countryCode: "ID",
    currencySymbol: "Rp",
    currencyCode: "IDR",
  },
  {
    key: "2554",
    value: "nzd",
    text: "New Zealand",
    country: "New Zealand",
    countryCode: "NZ",
    currencySymbol: "$",
    currencyCode: "NZD",
  },
];

const AdsSpyLocationDropdown = (props) => {
  const { defaultValue, onChange } = props;
  const [value, setValue] = useState(locationOptions[0]);

  useEffect(() => {
    if (Object.keys(defaultValue).length !== 0) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const onClickMenu = (item) => {
    setValue(item);
    onChange(item);
  };

  return (
    <div className="currency-wrapper text-center m-3 ">
      <div className="">
        <label className="currency-label">Country</label>
        <Dropdown
          overlay={
            <Menu>
              {locationOptions.map((item, key) => (
                <Menu.Item key={key} onClick={() => onClickMenu(item)}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {item.text}
                    <div>
                      <img
                        src={`/country/${item.countryCode.toLowerCase()}.png`}
                        alt={item.countryCode}
                        style={{ marginRight: "0" }}
                      />
                    </div>
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomLeft"
          arrow
          className="currency-dropdown"
          AdsSpyLocationDropdown
        >
          {/* <Button>{value.text} <DownOutlined style={{ float: 'right', marginTop: '6px' }} /></Button> */}
          <Button>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {value.text}
              <div
                className="mx-2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <img
                  src={
                    value.countryCode
                      ? `/country/${value.countryCode.toLowerCase()}.png`
                      : ""
                  }
                  alt={value.countryCode ? value.countryCode : ""}
                  style={{ margin: "5px", maxHeight: "1em" }}
                />
                <DownOutlined style={{ float: "right", marginTop: "6px" }} />
              </div>
            </div>
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default AdsSpyLocationDropdown;

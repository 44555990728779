import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import { Title } from '../aiAds/AiAdsGenerator';
import '../../app.css';
import firebase from 'firebase/compat/app';
import { enUS } from 'date-fns/locale';
import { Option } from 'antd/lib/mentions';
import Cookies from 'js-cookie';
import { DateRangePicker } from 'react-date-range';
import backIcon from "../../styles/icons/back.svg";
import { addDays } from 'date-fns';
import SingleLineChart from './components/SingleLineChart';
import { googleAdsApiService } from '../../services/googleApiService';
import ColumnPieChart from './components/ColumnPieChart';
import DualLineChart from './components/DualLineChart';
import ClusteredColumnChart from './components/ClusteredColumnChart';

const GoogleAnalytics = () => {
    const [loginState, setloginState] = useState(false);
    const [access_token, setaccessToken] = useState("");
    const [adAccounts, setAdAccounts] = useState([]);
    const [campValue, setCampValue] = useState([]);
    const [adAccountValue, setAdAccountValue] = useState();
    const [userInsideData, setUserInsideData] = useState([]);
    const [adsDetailUi, setAdDetailsUi] = useState(false);
    const [adcamp, setadcamp] = useState([]);
    const [topItemsList, setTopItems] = useState([]);
    const [deviceUsers, setDeviceusers] = useState([]);
    const [weekNamePurchases, setWeekNamePurchases] = useState([]);
    const [stateUsers, setStateUsers] = useState([]);
    const [userPlatform, setuserPlatform] = useState([]);
    const [loading, setLoadingState] = useState(false);

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);
    const [showDatePicker, setShowDatePicker] = useState(false);

    const handleGoogleLoginClick = () => {
        const client_id = '125927670553-lmeh23m630mhftc7lviefssjggmtatbp.apps.googleusercontent.com';
        const redirect_uri = "http://localhost:3000/leads-google-analytics";
        const scope = "https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/analytics.readonly";
        const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${encodeURIComponent(scope)}&access_type=offline&prompt=consent`;
        window.location.href = url;
    };

    useEffect(() => {
         const getGoogleLogin = async () => {
               let google_data = null;
          if (!check_user_login(false)) {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            if (code) {
               check_user_login(true);
               google_data = await googleAdsApiService("http://localhost:3000/leads-google-analytics");
               update_ui(google_data);
            }   
          }else{
              google_data = await googleAdsApiService("http://localhost:3000/leads-google-analytics");
              update_ui(google_data);
          }
            //  update_ui(google_data);
        }

        getGoogleLogin();
    }, []);

    const check_user_login = (save) => {
        const id = firebase.auth().currentUser.uid;
        if (save) {
            Cookies.set(id, 'true', { expires: 3650, path: '/' });
            setloginState(true);
            return true;
        }
        else {
            if (Cookies.get(id)) {
                setloginState(true);
                return true;
            }
        }
        return false;
    }

    const update_ui = async (google_data) => {
        check_user_login(false);
        if (google_data) {
            if (google_data.access_token) {
                 setaccessToken(google_data.access_token);
                 setloginState(true);
                 const id = await fetch_customer_id(google_data.access_token);
                 setAdAccounts(id);
         } else (
          console.log("No access token")
          )
       }
    }

    const fetch_kpis = async (value) => {
        const start = new Date(state[0].startDate);
        const end = new Date(state[0].endDate);
        const startDate = formatDateInFB(start);
        const endDate = formatDateInFB(end);
        const response = await fetch('https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/google_analytics_data/fetch-analytics-data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accessToken: access_token,
                propertyId: value,
                startDate: startDate,
                endDate: endDate
            })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    const fetch_top_items = async (value, startDate, endDate) => {
        const response = await fetch('https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/google_analytics_data/fetch-analytics-top-items', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accessToken: access_token,
                propertyId: value,
                startDate: startDate,
                endDate: endDate
            })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    const fetch_Device_Users = async (value, startDate, endDate) => {
        const response = await fetch('https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/google_analytics_data/fetch-analytics-user-devices', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accessToken: access_token,
                propertyId: value,
                startDate: startDate,
                endDate: endDate
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    const fetch_weekName_Purchase = async (value, startDate, endDate) => {
        const response = await fetch('https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/google_analytics_data/fetch-analytics-weekName-purchase', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accessToken: access_token,
                propertyId: value,
                startDate: startDate,
                endDate: endDate
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    const fetch_top_city = async (value, startDate, endDate) => {
        const response = await fetch('https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/google_analytics_data/fetch-analytics-top-city', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accessToken: access_token,
                propertyId: value,
                startDate: startDate,
                endDate: endDate
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    const fetch_user_platform = async (value, startDate, endDate) => {
        const response = await fetch('https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/google_analytics_data/fetch-analytics-user-platform', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accessToken: access_token,
                propertyId: value,
                startDate: startDate,
                endDate: endDate
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    //Selections Handling
    const selectedAdAccount = (value) => {
        setAdAccountValue(value);
        fetch_campaigns(value)
    };

  
      
  const fetch_customer_id = async (access_token) =>{
    const response = await fetch('https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/google_analytics_data/fetch-google-analytics-accounts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            accessToken: access_token
          })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  }

    const  fetch_campaigns = async (value) => {
        const response = await fetch('https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/google_analytics_data/fetch-google-analytics-campaigns', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accessToken: access_token,
                accountId: value
            })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        setadcamp(data);  
       }

    const selectedCampaignValue = (value) => {
        setCampValue(value);
    };

    const getReports = async () => {
        if (campValue != null) {
            try {
                setLoadingState(true);
    
                const user_insides = await fetch_kpis(campValue);
                const userPlateforms = await fetch_user_platform(campValue, formatDateInFB(state[0].startDate), formatDateInFB(state[0].endDate));
                const topItems = await fetch_top_items(campValue, formatDateInFB(state[0].startDate), formatDateInFB(state[0].endDate));
                const device_data = await fetch_Device_Users(campValue, formatDateInFB(state[0].startDate), formatDateInFB(state[0].endDate));
                const weekPurchase = await fetch_weekName_Purchase(campValue, formatDateInFB(state[0].startDate), formatDateInFB(state[0].endDate));
                const statetype = await fetch_top_city(campValue, formatDateInFB(state[0].startDate), formatDateInFB(state[0].endDate));
           
                setUserInsideData(user_insides);
                setuserPlatform(userPlateforms);
                setTopItems(topItems);
                setDeviceusers(device_data);
                setWeekNamePurchases(weekPurchase);
                setStateUsers(statetype);
    
                if (
                    user_insides?.rows?.length > 0 &&
                    userPlateforms?.rows.length > 0 &&
                    topItems?.rows.length > 0 &&
                    device_data?.rows.length > 0 &&
                    weekPurchase?.rows.length > 0 &&
                    statetype?.rows.length > 0
                ) {
                    setLoadingState(false);
                    setAdDetailsUi(true);
                } else {
                    setLoadingState(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoadingState(false);
                setAdDetailsUi(false);
            }
        } else {
            alert("Select Campaign and Account First");
        }
    };

    const pressedBack = () => {
        setAdDetailsUi(false);
    }

    const handleSelect = (ranges) => {
        setState([ranges.selection]);
        setShowDatePicker(false);
    };

    function formatNumber(value) {
        const number = parseFloat(value).toFixed(2);
        const parts = number.toString().split('.');
        const integerPart = parts[0];
        let decimalPart = parts[1];
        let formattedNumber = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (decimalPart) {
            formattedNumber += '.' + decimalPart;
        }
        return formattedNumber;
    }

    const formatDateRange = (start, end) => {
        if (!start || !end) return '';
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return `${start.toLocaleDateString(undefined, options)} - ${end.toLocaleDateString(undefined, options)}`;
    };

    const formatDateInFB = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <div className='full-height' style={{ flexDirection: "column" }}>
            {loading ? (
                <div style={{width:"100%",height:"100%",display:'flex',justifyContent:"center",alignItems:"center",flexDirection:'column'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="200px" width="200px" viewBox="0 0 200 200" className="pencil">
                    <defs>
                        <clipPath id="pencil-eraser">
                            <rect height="30" width="30" ry="5" rx="5"></rect>
                        </clipPath>
                    </defs>
                    <circle transform="rotate(-113,100,100)" strokeLinecap="round" strokeDashoffset="439.82" strokeDasharray="439.82 439.82" strokeWidth="2" stroke="currentColor" fill="none" r="70" className="pencil__stroke"></circle>
                    <g transform="translate(100,100)" className="pencil__rotate">
                        <g fill="none">
                            <circle transform="rotate(-90)" strokeDashoffset="402" strokeDasharray="402.12 402.12" strokeWidth="30" stroke="hsl(223,90%,50%)" r="64" className="pencil__body1"></circle>
                            <circle transform="rotate(-90)" strokeDashoffset="465" strokeDasharray="464.96 464.96" strokeWidth="10" stroke="hsl(223,90%,60%)" r="74" className="pencil__body2"></circle>
                            <circle transform="rotate(-90)" strokeDashoffset="339" strokeDasharray="339.29 339.29" strokeWidth="10" stroke="hsl(223,90%,40%)" r="54" className="pencil__body3"></circle>
                        </g>
                        <g transform="rotate(-90) translate(49,0)" className="pencil__eraser">
                            <g className="pencil__eraser-skew">
                                <rect height="30" width="30" ry="5" rx="5" fill="hsl(223,90%,70%)"></rect>
                                <rect clipPath="url(#pencil-eraser)" height="30" width="5" fill="hsl(223,90%,60%)"></rect>
                                <rect height="20" width="30" fill="hsl(223,10%,90%)"></rect>
                                <rect height="20" width="15" fill="hsl(223,10%,70%)"></rect>
                                <rect height="20" width="5" fill="hsl(223,10%,80%)"></rect>
                                <rect height="2" width="30" y="6" fill="hsla(223,10%,10%,0.2)"></rect>
                                <rect height="2" width="30" y="13" fill="hsla(223,10%,10%,0.2)"></rect>
                            </g>
                        </g>
                        <g transform="rotate(-90) translate(49,-30)" className="pencil__point">
                            <polygon points="15 0,30 30,0 30" fill="hsl(33,90%,70%)"></polygon>
                            <polygon points="15 0,6 30,0 30" fill="hsl(33,90%,50%)"></polygon>
                            <polygon points="15 0,20 10,10 10" fill="hsl(223,10%,10%)"></polygon>
                        </g>
                    </g>
                    </svg>

                    <p style={{ color: '#4BB543', fontWeight: '700', textAlign: 'center', marginTop: '30px' }}>
                    😊  Please wait! While we are drawing the charts for you  😊
                    </p>
                </div>
            ) : (
                <div style={{ width: '100%', height: '100%' }}>
                    {loginState ? (
                        <div className='facebook-header'>
                            <div className='facebook-header-row'>
                                <img src={backIcon} alt='back' onClick={pressedBack} style={{ cursor: "pointer" }} />
                                <div className="date-picker-container" style={{ color: "black", fontWeight: "600" }}>
                                    <div onClick={() => setShowDatePicker(!showDatePicker)} style={{ cursor: 'pointer', marginBottom: '10px' }}>
                                        {formatDateRange(state[0].startDate, state[0].endDate)} {showDatePicker ? "▲" : "▼"}
                                    </div>
                                    {showDatePicker && (
                                        <div className="date-picker-dropdown">
                                            <DateRangePicker
                                                onChange={handleSelect}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={state}
                                                direction="horizontal"
                                                locale={enUS}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <Row align='top' justify='top' style={{ width: "95%" }}>
                        {userInsideData.rows && userInsideData.rows.length > 0 && adsDetailUi && !loading ? (
                            <Col span={24} justify='top' align='top'>
                                <div className='facebook-background-container'>
                                    <div className='facebook-value-card-container'>
                                        <p style={{ fontWeight: "600" }}>Sessions</p>
                                        <h2 style={{ color: "black", fontSize: "16px", paddingTop: "20px", fontWeight: "600" }}>
                                            {formatNumber(userInsideData?.totals[0]?.metricValues[2]?.value || '0').replace(".00", "")}
                                        </h2>
                                    </div>
                                    <div className='facebook-value-card-container'>
                                        <p style={{ fontWeight: "600" }}>Add To Cart</p>
                                        <h2 style={{ color: "black", fontSize: "16px", paddingTop: "20px", fontWeight: "600" }}>
                                            {formatNumber(userInsideData?.totals[0]?.metricValues[0]?.value || '0').replace('.00', '')}
                                        </h2>
                                    </div>
                                    <div className='facebook-value-card-container'>
                                        <p style={{ fontWeight: "600" }}>New Users</p>
                                        <h2 style={{ color: "black", fontSize: "16px", paddingTop: "20px", fontWeight: "600" }}>
                                            {formatNumber(userInsideData?.totals[0]?.metricValues[1]?.value || '0').replace(".00", "")}
                                        </h2>
                                    </div>
                                    <div className='facebook-value-card-container'>
                                        <p style={{ fontWeight: "600" }}>Transaction</p>
                                        <h2 style={{ color: "black", fontSize: "16px", paddingTop: "20px", fontWeight: "600" }}>
                                            {formatNumber(userInsideData?.totals[0]?.metricValues[4]?.value || '0').replace(".00", "")}
                                        </h2>
                                    </div>
                                    <div className='facebook-value-card-container'>
                                        <p style={{ fontWeight: "600" }}>Total Revenue</p>
                                        <h2 style={{ color: "black", fontSize: "16px", paddingTop: "20px", fontWeight: "600" }}>₹ {formatNumber(userInsideData?.totals[0]?.metricValues[3]?.value || '0')}</h2>
                                    </div>
                                </div>

                                <div className='facebook-value-card-normal-container' style={{ height: "auto", marginBottom: "10%" }}>
                                    <div className='facebook-plane-column' style={{ width: "100%" }}>
                                        <div className='facebook-value-card-container' style={{ height: "auto", margin: "0px" }}>
                                            <h6>Top6 Item Category by First Time Purchaser</h6>
                                                <SingleLineChart totalImpressions={0} totalClicks={0} chart_data={topItemsList.rows} XLableValue={"Item Categories"} YLableValue={"Purchaser"} />
                                          </div>

                                        <div className='facebook-value-card-container' style={{ height: "auto", marginTop: "20px", marginBottom: "20px" }}>
                                            <h6>AddToCarts and Ecommerce Purchases</h6>
                                            <DualLineChart data={weekNamePurchases.rows} type={"Google"} cat1={"Added in Cart"} cat2={"Ecommerce Purchase"} Xval={"Day of Week Name"} Yval={"Card added and Purchases"} />
                                        </div>

                                        <div className='facebook-value-card-container' style={{ height: "auto", margin: "0px" }}>
                                            <h6>Active User by Top 5 City</h6>
                                            <ClusteredColumnChart rawData={stateUsers.rows} />
                                        </div>
                                    </div>
                                    <div className='facebook-plane-column' style={{ width: "40%", height: "100%" }}>

                                        <div className='facebook-value-card-container' style={{ width: "93%", height: "100%" }}>
                                            <p style={{ color: "black" }}>Total Users by DeviceCategory</p>
                                            <ColumnPieChart deviceData={deviceUsers} />
                                        </div>

                                        <div className='facebook-value-card-container' style={{ width: "93%", height: "100%", marginTop: "50px" }}>
                                            <p style={{ color: "black" }}>TotalUsers by Platform</p>
                                            <ColumnPieChart deviceData={userPlatform} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ) : (
                            !loginState ? (
                                <Row align='middle' justify='center' style={{ width: '100%' }}>
                                    <Col span={24} justify='center' align='middle'>
                                        <Title style={{ marginTop: "80px" }}>Login With Google to Check Your AdScore</Title>
                                        <div style={{ marginTop: '30px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <button className="google-login-button" onClick={handleGoogleLoginClick}>
                                                Continue with Google
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                <Col span={24} justify='center' align='middle'>
                                    <Title style={{ marginTop: "20px" }}>Check your Google Analytics in One Click</Title>
                                    <div style={{ marginBottom: '20px' }}>
                                        <h2 style={{ color: "#0d6efd", fontSize: "18px", marginBottom: '20px', marginTop: "30px" }}>Select your Analytics Accounts</h2>
                                        <Select
                                            placeholder="Select Ad Account"
                                            style={{ width: '80%' }}
                                            onChange={selectedAdAccount}>
                                            {adAccounts.map(accounts => (
                                                <Option key={accounts.id} value={accounts.id}>{accounts.name} ({accounts.id})</Option>
                                            ))}
                                        </Select>

                                        <h2 style={{ color: "#0d6efd", fontSize: "18px", marginTop: "40px" }}>Select your Properties/Apps</h2>
                                        <p style={{ fontSize: "12px" }}>This Properties/Apps from your account which you select Previously</p>

                                        <Select
                                            placeholder="Select Campaign"
                                            style={{ marginTop: "30px", marginBottom: "20px", width: "80%" }}
                                            onChange={selectedCampaignValue}>
                                            {adcamp.map(campaigns => (
                                                <Option key={campaigns.propertyId.replace('properties/', '')} value={campaigns.propertyId}>{campaigns.propertyName} ({campaigns.propertyId.replace('properties/', '')})</Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <button className='custom-facebook-report-button' onClick={getReports}>Get Report</button>
                                </Col>
                            )
                        )}
                    </Row>
                </div>
            )}
        </div>
    );
};

export default GoogleAnalytics;

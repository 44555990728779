// for facebook
const checkKeywords = (text, prohibitedKeywords) => {
  let violations = [];
  Object.keys(prohibitedKeywords).forEach((category) => {
    prohibitedKeywords[category].forEach((word) => {
      if (text.toLowerCase().includes(word.toLowerCase())) {
        violations.push(`${category}: ${word}`);
      }
    });
  });
  return violations;
};
const prohibitedKeywords = {
  counterfeit_goods: ["fake", "replica", "knockoff", "imitation"],
  dangerous_products: [
    "weapons",
    "drugs",
    "explosives",
    "tobacco",
    "firearms",
    "gun",
  ],
  adult_content: ["nudity", "sexually suggestive", "explicit"],
  prohibited_practices: ["phishing", "malware", "hacking", "spyware"],
  restricted_content: ["alcohol", "gambling", "adult", "political"],
  personal_attributes: [
    "race",
    "ethnicity",
    "religion",
    "age",
    "sexual orientation",
    "gender identity",
    "disability",
  ],
  misinformation: ["false", "fake news", "misleading", "gossip"],
  profanity: [
    "curse",
    "swear",
    "expletive",
    "obscenity",
    "cuss",
    "vulgarism",
    "epithet",
  ],
};

const misrepresentationKeywords = {
  AbsoluteClaims: [
    "Guaranteed results",
    "100% success rate",
    "Always effective",
    "Instant cure",
    "No risk involved",
  ],
  ExaggeratedSuperlatives: [
    "Best in the world",
    "Number 1",
    "Top-rated",
    "Most effective",
    "Highest quality",
  ],
  FalseAuthority: [
    "FDA approved",
    "Doctor recommended",
    "Official partner",
    "Government endorsed",
  ],
  FalsePricing: [
    "Lowest price guaranteed",
    "50% off today only",
    "No hidden fees",
    "Limited-time offer",
  ],
  UnsupportedHealthClaims: [
    "Lose 10 pounds in a week",
    "Cures all diseases",
    "Clinically proven",
    "Miracle solution",
  ],
  MisleadingDescriptions: [
    "Natural",
    "Organic",
    "Safe for everyone",
    "New and improved",
  ],
  FalsePromises: [
    "Money-back guarantee",
    "Exclusive offer",
    "No questions asked",
    "Results within 24 hours",
  ],
};

const checkGoogleKeywords = (text, keywordList) => {
  let violations = [];
  Object.keys(keywordList).forEach((category) => {
    keywordList[category].forEach((word) => {
      if (text.toLowerCase().includes(word.toLowerCase())) {
        violations.push({
          category: "Keyword Violation",
          subcategory: category,
          violation: word,
        });
      }
    });
  });
  return violations;
};

const checkExcessiveCapitalization = (text) => {
  let pattern = /\b[A-Z]{4,}\b/g;
  let matches = text.match(pattern);
  if (matches) {
    return [
      {
        category: "Capitalization Violation",
        subcategory: "Excessive Capitalization",
        violation: matches.join(", "),
      },
    ];
  }
  return [];
};

const checkPunctuationAndSymbols = (text) => {
  let violations = [];
  let repeatedFullStopPattern = /\.{2,}/g;
  let invalidSymbolsPattern = /[^a-zA-Z0-9\s',\.]/g;

  if (repeatedFullStopPattern.test(text)) {
    violations.push({
      category: "Punctuation Violation",
      subcategory: "Repeated Full Stops",
      violation: "Repeated full stops are not allowed.",
    });
  }
  if (invalidSymbolsPattern.test(text)) {
    violations.push({
      category: "Symbol Violation",
      subcategory: "Invalid Symbols",
      violation: "Invalid symbols detected.",
    });
  }
  return violations;
};

export const aiGeneratorService = async (params) => {

  const api_key =
    "sk-c1v-LZ8kYRJzJa_hV9SpfL6WdjfwUHs4nWD3HpVkybT3BlbkFJ5krgm1Lv9EW9mtNoTsBLsbjHPO0d323q9EtiqeOjQA";
  let prompt, systemContent;

  // Determine the ad type and prompt based on searchTypeGeneral and choice
  if (params.searchTypeGeneral === "facebook") {
    const prohibitedKeywords = {
      personal_attributes: [
        "race",
        "ethnicity",
        "religion",
        "age",
        "sexual orientation",
        "gender identity",
        "disability",
      ],
      illegal_products: [
        "drugs",
        "firearms",
        "explosives",
        "weapons",
        "gun",
        "tobacco",
      ],
      adult_content: ["nudity", "sexually suggestive", "explicit", "sexual"],
      misinformation: ["false", "fake", "misleading", "gossip"],
      profanity: [
        "curse",
        "language",
        "swear",
        "expletive",
        "obscenity",
        "cuss",
        "vulgarism",
        "epithet",
      ],
    };
    const content = params.productName + " " + params.productDescription;
    const violations = checkKeywords(content, prohibitedKeywords);

    if (violations.length > 0) {
      return `Content violates Meta Advertising Policies. Violations: ${violations.join(
        ", "
      )}`;
    }

    if (params.choice === "Simple Facebook Ad") {
      prompt = `Write a creative ad for the following product to run on Facebook.\nProduct: ${params.productName}\nDescription: ${params.productDescription}`;
    } else if (params.choice === "Advanced Facebook Ad") {
      prompt = `Write a creative ad for the following product to run on Facebook.\nProduct: ${params.productName}\nDescription: ${params.productDescription}\n\nPain: [Describe the pain point]\nAgitate: [Describe how the pain point affects the user]\nSolution: [Describe how the product solves the pain point]`;
    } else if (params.choice === "Facebook Headlines") {
      prompt = `Write a creative ad for the following product to run on Facebook Ads.\nProduct: ${params.productName}\nDescription: ${params.productDescription}\n\nHeadline 1:\nHeadline 2:\nHeadline 3:`;
    } else {
      return "Invalid choice for Facebook ad. Please choose a valid option.";
    }
    systemContent =
      "You are a creative ad copywriter specializing in Facebook ads.";
  } else if (params.searchTypeGeneral === "GOOGLE") {
    let adText = `${params.productName} ${params.productDescription}`;
    let allViolations = [];

    // Check for keyword violations
    allViolations.push(...checkGoogleKeywords(adText, prohibitedKeywords));
    allViolations.push(
      ...checkGoogleKeywords(adText, misrepresentationKeywords)
    );

    // Check for excessive capitalization
    allViolations.push(...checkExcessiveCapitalization(adText));

    // Check for punctuation and symbols
    allViolations.push(...checkPunctuationAndSymbols(adText));

    // setViolations(allViolations);
    if (allViolations.length > 0) {
      return `Content violates Google Advertising Policies. Violations: ${allViolations[0].subcategory}`;
    }

    prompt = `Write a creative ad for the following product to run on Google.\n\nProduct: ${params.productName}\nAbout: ${params.productDescription}\n\nHeadline 1:\nHeadline 2:\nHeadline 3:\nDescription 1:\nDescription 2:`;
    systemContent =
      "You are a creative ad copywriter specializing in Google ads.";
  } else {
    return "Invalid ad type. Please choose 'Facebook' or 'Google'.";
  }

  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${api_key}`, // Replace `api_key` with your actual API key
      },
      body: JSON.stringify({
        // model: "gpt-4",
        model: "gpt-3.5-turbo",
        messages: [
          { role: "system", content: systemContent },
          { role: "user", content: prompt },
        ],
        max_tokens: 350,
        n: 1,
        stop: null,
        temperature: 0.7,
      }),
    });
    if (response.ok) {
      const result = await response.json();
      return result.choices[0].message.content.trim();
    } else {
      const errorContent = await response.text();
      throw new Error(`API request failed: ${errorContent}`);
    }
  } catch (error) {
    throw new Error(`An error occurred: ${error.message}`);
  }
};

import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Modal,
  Radio,
  Row,
  notification,
} from "antd";
// import { collection, doc, getDocs, query, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { PAGE_HEAD_TITLE } from "../../constants";
// import { Database } from "../../firebase";
import { Header } from "../../styles/styles";
import UserContext from "../../userContext";
import { getUserAccountPlan, humanReadableNumber } from "../../utils/Helpers";
import { locationOptions } from "../locationDropdown";
import UserPaymentModal from "./PaymentModal";
import Plans from "./SubscriptionPlans.json";
const PlansComponent = (props) => {
  const authUser = useContext(UserContext);
  const [plans, setPlans] = useState(Plans.MonthlyPlans);
  const [planMonthlyYearly, setPlanMonthlyYearly] = useState("MONTHLY");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [planSelected, setPlanSelected] = useState(null);
  // const [locationData, setLocationData] = useState(locationOptions[6]);
  const [currencySelected, setCurrencySelected] = useState(
    locationOptions[6].currencyCode
  );
  const planDurationOptions = [
    { label: "Monthly", value: "MONTHLY" },
    { label: "Yearly", value: "YEARLY" },
  ];

  useEffect(() => {
    document.title = `Plans ${PAGE_HEAD_TITLE}`;
  }, []);

  const handlePeriodChange = (e) => {
    if (e.target.value === "MONTHLY") setPlans(Plans.MonthlyPlans);
    else setPlans(Plans.YearlyPlans);
    setPlanMonthlyYearly(e.target.value);
  };

  const showConfirm = (plan) => {
    Modal.confirm({
      className: "extra-pad-modal",
      title: `Do you want to purchase the ${plan.name} plan for ${plan.price}$?`,
      icon: <ExclamationCircleOutlined />,
      content: `Plan cost : ${plan.price}$`,
      onOk: () => {
        setPlanSelected(plan);
        setIsModalVisible(true);
      },
      onCancel: () => {
        notification["error"]({ message: "You have cancelled purchase!" });
      },
    });
  };

  const itemOrder = ["FREE", "TEST_PLAN", "BRONZE", "SILVER", "GOLD"];
  plans.sort(
    (a, b) => itemOrder.indexOf(a.planId) - itemOrder.indexOf(b.planId)
  );

  return (
    <div className="content-wrapper">
      {/* top  */}
      <div className="subscription-top-content">
        {/* subscription-top-header */}
        <div className="subscription-top-header">
          <Header style={{ fontSize: "40px" }}>Subscription Plans</Header>
          <p>
            Choose the perfect plan for your needs. All plans include basic
            features with additional
          </p>
          <p>perks as you scale.</p>
        </div>
        {/* currency or year  */}
        <div className="subscription-top-currency-year">
          {/* select year or month plan  */}
          <div>
            <Radio.Group
              options={planDurationOptions}
              onChange={handlePeriodChange}
              value={planMonthlyYearly}
              optionType="button"
              buttonStyle="solid"
              size="small"
              className="subscription-select-year-month"
            />
          </div>
        </div>
      </div>

      <Row gutter={16}>
        {plans &&
          plans.map((plan, index) => {
            return (
              <Col
                span={6}
                key={`plan-idx-${index}`}
                style={{ marginTop: "50px" }}
              >
                <Card
                  className={`subscription-card ${
                    plan.planId === "SILVER" ? "popular-plan" : ""
                  }`}
                >
                  {/* subscription details  */}
                  <div className="">
                    {/* plan name  */}
                    <div className="popular-plan-title">
                      <p className="plan-title">{plan.name.toUpperCase()}</p>
                      {plan.name === "Silver" && (
                        <p className="popular-plan-tag">Popular</p>
                      )}
                    </div>
                    {/* plan heading  */}
                    <p>{plan.heading}</p>

                    {/* plan price  */}
                    {plan.name === "Free" ? (
                      <p>
                        <span className="plan-prices">{plan.price}$</span>
                        <span>&nbsp;&nbsp;&nbsp;forever</span>
                      </p>
                    ) : (
                      <p>
                        <span className="plan-prices">{plan.price}$</span>
                        {planMonthlyYearly === "MONTHLY" ? (
                          <span>&nbsp;&nbsp;&nbsp; /month</span>
                        ) : (
                          <span>&nbsp;&nbsp;&nbsp; /year</span>
                        )}
                      </p>
                    )}
                    {/* plan validity  */}
                    <p>
                      <b>Validity (days):</b> <span>{plan.validity}</span>
                    </p>
                    {/* hiddenreque  */}
                    <p>
                      <b>Facebook Hidden Interest:</b>{" "}
                      <span>{plan.fbhiddenRequests}</span>
                    </p>
                    {/* aiRequests */}
                    <p>
                      <b>AI Request:</b> <span>{plan.aiRequests}</span>{" "}
                      Characters
                    </p>
                    {/* keywordSeach */}
                    <p>
                      <b>Keyword Research:</b> <span>{plan.keywordSeach}</span>{" "}
                      Results
                    </p>
                    {/* spyAds */}
                    <p>
                      <b>Spy Ads Research Quota:</b> <span>{plan.spyAds}</span>{" "}
                      Results
                    </p>
                    {/* marketingAnalytics */}
                    <p>
                      <b>Marketing Analytics</b>{" "}
                      <span>{plan.marketingAnalytics}</span>
                    </p>
                    {/* aiImageAnalyzer */}
                    <p>
                      <b>Ad Image Analyzer</b>{" "}
                      <span>{plan.aiImageAnalyzer}</span>
                    </p>
                  </div>
                  {/* button   */}
                  {plan.name === "Free" && (
                    <button className="subscription-plan-button subscription-plan-button-blank">
                      Get Started
                    </button>
                  )}
                  {plan.name === "Bronze" && (
                    <button
                      className="subscription-plan-button subscription-plan-button-blank"
                      subscription-plan-button-blank
                      onClick={() => {
                        showConfirm(plan);
                      }}
                    >
                      Subscribe
                    </button>
                  )}
                  {plan.name === "Silver" && (
                    <button
                      className="subscription-plan-button "
                      onClick={() => {
                        showConfirm(plan);
                      }}
                    >
                      Subscribe Now
                    </button>
                  )}
                  {plan.name === "Gold" && (
                    <button
                      className="subscription-plan-button subscription-plan-button-blank"
                      onClick={() => {
                        showConfirm(plan);
                      }}
                    >
                      Subscribe
                    </button>
                  )}
                </Card>
              </Col>
            );
          })}
      </Row>

      <UserPaymentModal
        authUser={authUser}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        planMonthlyYearly={planMonthlyYearly}
        currencySelected={currencySelected}
        planSelected={planSelected}
      />
    </div>
  );
};

export default PlansComponent;

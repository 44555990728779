import logo from "../../../styles/images/facebooklogo.svg";

import BootstrapTable from "react-bootstrap-table-next";
import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";
import { calculatePercentageChange } from "../../../utils/Helpers";
import { useState } from "react";

const CustomTooltip = ({ children, tooltipText, date }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <span
      style={{ position: "relative", cursor: "pointer" }}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: "-60",
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
            whiteSpace: "nowrap",
            fontSize: "12px",
            zIndex: 10,
          }}
        >
          {/* Tooltip Text with border */}
          <div
            style={{
              borderBottom: "1px solid #ddd",
              borderRadius: "4px",
              padding: "4px",
              marginRight: "4px", // Space between the columns
              flex: 1, // Ensures this column takes up available space
            }}
            dangerouslySetInnerHTML={{ __html: tooltipText }}
          />

          {/* Date Field with border */}
          <div
            style={{
              padding: "4px",
              flex: 0, // Keeps the date field size fixed
            }}
          >
            Compare To : {date}
          </div>
        </div>
      )}
    </span>
  );
};

function formatDates(...dateStrings) {
  return dateStrings
    .map((dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("en-IN", { month: "short" });
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    })
    .join(" ");
}

const CampaginTableEcom = ({ date, data, prvdata }) => {
  // Formatters for trend indicators
  const combinedData = data.map((currentItem) => {
    // Find matching item in prvdata by `campaign_name`
    const previousItem = prvdata.find(
      (prevItem) => prevItem.id === currentItem.id
    );

    // If there's no previous item, return the current item with no changes
    if (!previousItem) {
      return {
        ...currentItem,
        previousDate: "No Previous Data",

        purchaseConversionChange: 0,
        amountSpentChange: 0,
        roasChange: 0,
        weebsitepurchaseChange: 0,
        costperwebpurchaseChange: 0,

        amountSpent: currentItem?.spend,

        roas: parseFloat(
          currentItem.purchase_roas?.find(
            (action) => action.action_type === "omni_purchase"
          )?.value || 0
        ).toFixed(2),

        purchaseConversion: parseFloat(
          (
            (currentItem?.spend || 0) *
            (currentItem.purchase_roas?.find(
              (action) => action.action_type === "omni_purchase"
            )?.value || 0)
          ).toFixed(2)
        ),

        weebsitepurchase:
          currentItem.actions?.find(
            (action) => action.action_type === "omni_purchase"
          )?.value || 0,

        costperwebpurchase: parseFloat(
          currentItem.cost_per_action_type?.find(
            (action) => action.action_type === "omni_purchase"
          )?.value || 0
        ).toFixed(2),
      };
    }

    // Current values
    const currentSpend = parseFloat(currentItem?.spend) || 0;
    const currentRoas =
      parseFloat(
        currentItem?.purchase_roas?.find(
          (action) => action.action_type === "omni_purchase"
        )?.value
      ) || 0;
    const currentPurchaseConversion = parseFloat(currentRoas * currentSpend);
    const currentWebsitepurchase =
      parseFloat(
        currentItem?.actions?.find(
          (action) => action.action_type === "omni_purchase"
        )?.value
      ) || 0;
    const currentCostPerWebpurchase =
      parseFloat(
        currentItem?.cost_per_action_type?.find(
          (action) => action.action_type === "omni_purchase"
        )?.value
      ) || 0;

    // Previous values
    const previousSpend = previousItem ? parseFloat(previousItem.spend) : 0;

    const previousRoas = previousItem
      ? parseFloat(
          previousItem.purchase_roas?.find(
            (action) => action?.action_type === "omni_purchase"
          )?.value
        ) || 0
      : 0;
    const previousPurchaseConversion = previousItem
      ? parseFloat(previousSpend * previousRoas)
      : 0;

    const previousWebsitepurchase = previousItem
      ? parseFloat(
          previousItem.actions?.find(
            (action) => action?.action_type === "omni_purchase"
          )?.value
        ) || 0
      : 0;
    const previousCostPerWebpurchase = previousItem
      ? parseFloat(
          previousItem.cost_per_action_type?.find(
            (action) => action?.action_type === "omni_purchase"
          )?.value
        ) || 0
      : 0;

    const prevDate = previousItem?.date_start;
    const prevEnd = previousItem?.date_stop;

    return {
      ...currentItem,
      previousDate: formatDates(prevDate, prevEnd),
      previousamountSpent: previousSpend,
      previousRoas: previousRoas,
      previousPurchaseConversion: previousPurchaseConversion,
      previousWebsitepurchase: previousWebsitepurchase,
      previousCostPerWebpurchase: previousCostPerWebpurchase,

      amountSpent: currentSpend,
      roas: currentRoas,
      purchaseConversion: currentPurchaseConversion,
      weebsitepurchase: currentWebsitepurchase,
      costperwebpurchase: currentCostPerWebpurchase,

      amountSpentChange: calculatePercentageChange(currentSpend, previousSpend),
      purchaseConversionChange: calculatePercentageChange(
        currentPurchaseConversion,
        previousPurchaseConversion
      ),
      roasChange: calculatePercentageChange(currentRoas, previousRoas),

      weebsitepurchase: calculatePercentageChange(
        currentWebsitepurchase,
        previousWebsitepurchase
      ),
      costperwebpurchase: calculatePercentageChange(
        currentCostPerWebpurchase,
        previousCostPerWebpurchase
      ),
    };
  });

  const createDynamicFormatter = (label, valueKey) => (cell, row) => {
    const previousValue = row[`previous${valueKey}`];
    const value = row[valueKey];
    const changeValue = row[`${valueKey}Change`] || 0;

    // No previous data, do not show trend
    if (previousValue === "No Previous Data") {
      return <span>₹ {value}</span>;
    }

    const tooltipText = `${label}: ₹ <strong>${previousValue}</strong> | ${
      changeValue > 0 ? "+" : ""
    }${changeValue.toFixed(2)}%`;

    return (
      <span>
        ₹ {value}{" "}
        <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "4px 10px",
              borderRadius: "10px",
              backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
              color: changeValue > 0 ? "#3C763D" : "#A94442",
            }}
          >
            {changeValue > 0 ? (
              <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
            ) : (
              <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
            )}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {Math.abs(changeValue).toFixed(2)}%
            </span>
          </div>
        </CustomTooltip>
      </span>
    );
  };

  const ValueFormatter = (label, valueKey) => (cell, row) => {
    const previousValue = row[`previous${valueKey}`];
    const value = row[valueKey];
    const changeValue = row[`${valueKey}Change`] || 0;

    // No previous data, do not show trend
    if (previousValue === "No Previous Data") {
      return <span>{value}</span>;
    }

    const tooltipText = `${label}: <strong>${previousValue}</strong> | ${
      changeValue > 0 ? "+" : ""
    }${changeValue.toFixed(2)}%`;

    return (
      <span>
        {value}{" "}
        <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "4px 10px",
              borderRadius: "10px",
              backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
              color: changeValue > 0 ? "#3C763D" : "#A94442",
            }}
          >
            {changeValue > 0 ? (
              <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
            ) : (
              <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
            )}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {Math.abs(changeValue).toFixed(2)}%
            </span>
          </div>
        </CustomTooltip>
      </span>
    );
  };

  const columns = [
    {
      dataField: "campaign_name",
      text: "Campaign Name",
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "purchaseConversion",
      text: "Purchase Conversion Value",
      formatter: createDynamicFormatter(
        "Purchase Conversion Value",
        "purchaseConversion"
      ),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "amountSpent",
      text: "Amount Spent",
      formatter: createDynamicFormatter("Amount Spent", "amountSpent"),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "roas",
      text: "ROAS(ALL)",
      formatter: ValueFormatter("ROAS", "roas"),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "weebsitepurchase",
      text: "Website Purchase",
      formatter: ValueFormatter("Website Purchase", "weebsitepurchase"),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "costperwebpurchase",
      text: "Cost Per Website Purchase",
      formatter: createDynamicFormatter(
        "Cost Per Website Purchase",
        "costperwebpurchase"
      ),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
  ];

  return (
    <div className="table-card">
      <h5 className="card-title">
        <img style={{ width: "24px", height: "24px" }} src={logo} alt="fb" />{" "}
        Performance Analysis for {date} by Campaign Name
      </h5>
      <p className="text-muted">
        Purchase Conversion Value, Amount Spent, ROAS(ALL), Website Purchase,
        Cost Per Website Purchase
      </p>
      <div className="table-scroll-wrapper">
        <BootstrapTable
          keyField="campaign_name"
          data={combinedData}
          columns={columns}
          bordered={false}
          hover
          condensed
          rowStyle={{ border: "none !important" }}
          classes="custom-table"
          noDataIndication={() => <div>No data available</div>}
        />
      </div>
    </div>
  );
};

export default CampaginTableEcom;

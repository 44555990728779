import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import logo from "../../../styles/images/facebooklogo.svg";
import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";
import "../components/TableWithTrends.css";
import { calculatePercentageChange } from "../../../utils/Helpers";

const CustomTooltip = ({ children, tooltipText, date }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <span
      style={{ position: "relative", cursor: "pointer" }}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: "-60",
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
            whiteSpace: "nowrap",
            fontSize: "12px",
            zIndex: 10,
          }}
        >
          {/* Tooltip Text with border */}
          <div
            style={{
              borderBottom: "1px solid #ddd",
              borderRadius: "4px",
              padding: "4px",
              marginRight: "4px", // Space between the columns
              flex: 1, // Ensures this column takes up available space
            }}
            dangerouslySetInnerHTML={{ __html: tooltipText }}
          />

          {/* Date Field with border */}
          <div
            style={{
              padding: "4px",
              flex: 0, // Keeps the date field size fixed
            }}
          >
            Compare To : {date}
          </div>
        </div>
      )}
    </span>
  );
};

function formatDates(...dateStrings) {
  return dateStrings
    .map((dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("en-IN", { month: "short" });
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    })
    .join(" ");
}

const DeviceTable = ({ prvdata, data, date }) => {
  // Hardcoded demo data
  const combinedData = data.map((currentItem) => {
    // Find matching item in prevdata by `impression_device`
    const previousItem = prvdata.find(
      (prevItem) => prevItem.impression_device === currentItem.impression_device
    );
    // curr values
    // curr spend
    const currentSpend = parseFloat(currentItem.spend) || 0;
    //curr cpm
    const CPM = currentItem ? parseFloat(currentItem.cpm) : 0;
    //   curr link click
    const currlinkclick =
      parseFloat(
        currentItem.actions?.find(
          (action) => action.action_type === "link_click"
        )?.value
      ) || 0;
    // curr checkoutinitiate
    const currcheckoutinitiate =
      parseFloat(
        currentItem.actions?.find(
          (action) => action.action_type === "initiate_checkout"
        )?.value
      ) || 0;
    // curr website purchase
    const currwebsitepurchases =
      parseFloat(
        currentItem.actions?.find((action) => action.action_type === "purchase")
          ?.value
      ) || 0;

    // Previous values

    // prev spend
    const previousSpend = previousItem ? parseFloat(previousItem.spend) : 0;
    // prev cpm
    const previousCpm = previousItem ? parseFloat(previousItem.cpm) : 0;
    //   prev link click
    const prevlinkclick = previousItem
      ? parseFloat(
          previousItem.actions?.find(
            (action) => action.action_type === "link_click"
          )?.value
        ) || 0
      : 0;
    // prev checkoutinitiate
    const prevcheckoutinitiate = previousItem
      ? parseFloat(
          previousItem.actions?.find(
            (action) => action.action_type === "initiate_checkout"
          )?.value
        ) || 0
      : 0;
    //   prev websitepurchase
    const prevwebsitepurchase = previousItem
      ? parseFloat(
          previousItem.actions?.find(
            (action) => action.action_type === "purchase"
          )?.value
        ) || 0
      : 0;

    const prevDate = previousItem?.date_start;
    const prevEnd = previousItem?.date_stop;

    return {
      ...currentItem,
      previousDate: formatDates(prevDate, prevEnd),
      previousamountSpent: previousSpend?.toFixed(2),
      previouscpm: previousCpm?.toFixed(2),
      prevlinkclick: prevlinkclick,
      prevcheckoutinitiate: prevcheckoutinitiate,
      prevwebsitepurchase: prevwebsitepurchase,

      amountSpent: currentSpend?.toFixed(2),
      cpm: CPM?.toFixed(2),
      linkclick: currlinkclick,
      checkoutinitiate: currcheckoutinitiate,
      websitepurchase: currwebsitepurchases,

      reachChange: calculatePercentageChange(
        parseFloat(currentItem.cpm),
        parseFloat(previousItem?.cpm || 0)
      ),
      amountSpentChange: calculatePercentageChange(currentSpend, previousSpend),
      cpmChange: calculatePercentageChange(CPM, previousCpm),

      linkclickChange: calculatePercentageChange(currlinkclick, prevlinkclick),

      checkoutinitiateChange: calculatePercentageChange(
        currcheckoutinitiate,
        prevcheckoutinitiate
      ),
      websitepurchaseChange: calculatePercentageChange(
        currwebsitepurchases,
        prevwebsitepurchase
      ),
    };
  });

  // Formatters for trend indicators
  const createDynamicFormatter = (label, valueKey) => (cell, row) => {
    const previousValue = row[`previous${valueKey}`];
    const value = row[valueKey];
    const changeValue = row[`${valueKey}Change`] || 0;
    const tooltipText = `${label}: ₹ <strong>${previousValue}</strong> | ${
      changeValue > 0 ? "+" : ""
    }${changeValue.toFixed(2)}%`;
    return (
      <span>
        ₹ {value}{" "}
        <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "4px 10px",
              borderRadius: "10px",
              backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
              color: changeValue > 0 ? "#3C763D" : "#A94442",
            }}
          >
            {changeValue > 0 ? (
              <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
            ) : (
              <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
            )}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {Math.abs(changeValue).toFixed(2)}%
            </span>
          </div>
        </CustomTooltip>
      </span>
    );
  };

  const ValueFormatter = (label, valueKey) => (cell, row) => {
    const previousValue = row[`previous${valueKey}`];
    const value = row[valueKey];
    const changeValue = row[`${valueKey}Change`] || 0;
    const tooltipText = `${label}: <strong>${previousValue}</strong> | ${
      changeValue > 0 ? "+" : ""
    }${changeValue.toFixed(2)}%`;

    return (
      <span>
        {value}{" "}
        <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "4px 10px",
              borderRadius: "10px",
              backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
              color: changeValue > 0 ? "#3C763D" : "#A94442",
            }}
          >
            {changeValue > 0 ? (
              <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
            ) : (
              <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
            )}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {Math.abs(changeValue).toFixed(2)}%
            </span>
          </div>
        </CustomTooltip>
      </span>
    );
  };

  const columns = [
    {
      dataField: "impression_device",
      text: "Impression Device",
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "amountSpent",
      text: "Amount Spent",
      formatter: createDynamicFormatter("Amount Spent", "amountSpent"),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "cpm",
      text: "CPM",
      formatter: createDynamicFormatter("CPM", "cpm"),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "linkclick",
      text: "Outbound Clicks",
      formatter: ValueFormatter("Outbound Clicks", "linkclick"),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "checkoutinitiate",
      text: "Checkouts Initiated",
      formatter: ValueFormatter("Checkouts Initiated", "checkoutinitiate"),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
    {
      dataField: "websitepurchase",
      text: "Website Purchases",
      formatter: createDynamicFormatter("Website Purchases", "websitepurchase"),
      headerStyle: { fontWeight: "600", fontSize: "13px" },
    },
  ];

  return (
    <div className="table-card">
      <h5 className="card-title">
        <img style={{ width: "24px", height: "24px" }} src={logo} alt="fb" />{" "}
        From which devices are purchases done? Meta Amount Spent to Website
        Purchases for {date}
      </h5>
      <p className="text-muted">
        ROAS (All), Amount Spent, Outbound Clicks, Checkouts Initiated, Website
        Purchases
      </p>
      <div className="table-scroll-wrapper">
        <BootstrapTable
          keyField="date_start"
          data={combinedData}
          columns={columns}
          bordered={false}
          hover
          condensed
          rowStyle={{ border: "none !important" }}
          classes="custom-table"
        />
      </div>
    </div>
  );
};

export default DeviceTable;

import { Button as AntButton, Row, notification } from "antd";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
} from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { PAGE_HEAD_TITLE } from "../../constants";
import AppContext from "../../context/AppContext";
import { Database } from "../../firebase";
import {
  getAdSpyderDetail,
  getSpyAdsDataApi,
} from "../../services/youtubeAdsApiService";
import BunnyPlayer from "../BunnyPlayer";
// import { pleaseSubscribe, humanReadableNumber } from "../../utils/Helpers";
import {
  checkQuotaValidation,
  updateRequestUserQuota,
} from "../../services/quotaCheckService";
import SearchInput from "../SearchInput";
import RadioButton from "../SearchRadioButton";
import AdsSpyLocationDropdown, {
  locationOptions,
} from "../adsSpyLocationDropdown";
import AdsSpySortDropdown, { sortOptions } from "../adsSpySortDropdown";
import "../keywordSearch/style.css";
import AmazonAd from "./amazonAd";
import BingAd from "./bingAd";
import BingEcomAd from "./bingecomAd";
import MyCollection from "./collection";
import AdsDetail from "./details";
import DisplayAd from "./displayAd";
import FacebookAd from "./facebook";
import FlipkartAd from "./flipkartAd";
import GoogleAd from "./googleAd";
import GoogleEcomAd from "./googleEcomAd";
import LinkedInAd from "./linkedinAd";
import RedditAd from "./redditAd";
import YoutubeAd from "./youtubeAd";
import axios from "axios";
import { Header } from "../../styles/styles";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
const YoutubeAds = (props) => {
  const { setLoadingFullScreen } = useContext(AppContext);

  const searchType = props.searchType;
  let heading;
  if (searchType === "YOUTUBE") heading = "Youtube Ads";
  else if (searchType === "GOOGLE_ECOM") heading = "Google Ecom Ads";
  else if (searchType === "GOOGLE_ADS") heading = "Google Ads";
  else if (searchType === "DISPLAY_ADS") heading = "Display Ads";
  else if (searchType === "FLIPKART_ADS") heading = "Flipkart Ads";
  else if (searchType === "REDDIT_ADS") heading = "Reddit Ads";
  else if (searchType === "LINKEDIN_ADS") heading = "Linkedin Ads";
  else if (searchType === "AMAZON_ADS") heading = "Amazon Ads";
  else if (searchType === "FACEBOOK_ADS") heading = "Facebook Ads";
  else if (searchType === "BING_ECOM_ADS") heading = "Bing Ecom Ads";
  else if (searchType === "BING_ADS") heading = "Bing Ads";

  const [spyAdsData, setSpyAdsData] = useState([]);
  const authUser = props.user;
  const { uid, accountType } = authUser;

  const [inputKey, setInputKey] = useState("");
  const [adsDetails, setAdsDetails] = useState(null);
  const [viewMyCollection, setViewMyCollection] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(locationOptions[0]);
  const [selectedSortKey, setSelectedSortKey] = useState(sortOptions[0]);
  const [selectedRadio, setSelectedRadio] = useState("Broad");
  const [selectedCallType, setSelectedCallType] = useState("Keyword");

  const urlSearchTypes = [
    "GOOGLE_ADS",
    "GOOGLE_ECOM",
    "FACEBOOK_ADS",
    "BING_ECOM_ADS",
    "BING_ADS",
    
  ];

  if (!urlSearchTypes.includes) {
    setSelectedCallType("Keyword");
  }
  useEffect(() => {}, []); //eslint-disable-line

  useEffect(() => {
    document.title = `${
      searchType.toLowerCase().charAt(0).toUpperCase() +
      searchType.toLowerCase().slice(1).replace("_", " ")
    } ${PAGE_HEAD_TITLE}`;

    // reset to default state. If searchType changes
    setInputKey("");
    setAdsDetails(null);
    setViewMyCollection(false);
    setSearchData([]);
    // setsearchCountries([])
    setSelectedCountry({});
    // setSelectedSortKey({});
    setSelectedCallType("Keyword");
  }, [searchType]); //eslint-disable-line
  const findKeywords = async (type) => {
    if (!inputKey) {
      notification["error"]({ message: "Please enter search keyword!" });
    } else {
      setLoadingFullScreen(true);

      const checkResponse = await checkQuotaValidation(
        "YOUTUBE_ADS",
        authUser,
        {
          searchType,
        }
      );
      if (checkResponse.valid === false) {
        setLoadingFullScreen(false);
      } else {
        let page = 1;
        if (type === "loadMore") {
          page = searchData.length / 20 + 1;
        }

        let data;
        const token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1NzY0Mzc1ODYzNDg3IiwibmFtZSI6ImNsaWVudC1hZHNweWRlciIsImFkbWluIjp0cnVlLCJpYXQiOjE3MjkxNjU5OTR9.kKFfhV0G1aOAvj8TnfflvjhsSc2SREI7GBgNgMDMTQA";
        let keyUrl = selectedCallType.toLowerCase();
        let boardPhrase = selectedRadio.toLowerCase();
        if (keyUrl === "url") boardPhrase = "text";
        if (!selectedSortKey.value) selectedSortKey.value = "latest";
        let apiUrl;
        if (searchType === "GOOGLE_ADS") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/google/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/google/${keyUrl}/${boardPhrase}/filter`;
          }
        } else if (searchType === "GOOGLE_ECOM") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/google-ecom/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/google-ecom/${keyUrl}/${boardPhrase}/filter`;
          }
        } else if (searchType === "FACEBOOK_ADS") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/facebook/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/facebook/${keyUrl}/${boardPhrase}/filter`;
          }
        } else if (searchType === "BING_ADS") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/bing/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/bing/${keyUrl}/${boardPhrase}/filter`;
          }
        }
        //not have url
        else if (searchType === "YOUTUBE") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/youtube/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/youtube/${keyUrl}/${boardPhrase}/filter`;
          }
        } else if (searchType === "LINKEDIN_ADS") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/linkedin/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/linkedin/${keyUrl}/${boardPhrase}/filter`;
          }
        } else if (searchType === "AMAZON_ADS") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/amazon/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/amazon/${keyUrl}/${boardPhrase}/filter`;
          }
        } else if (searchType === "FLIPKART_ADS") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/flipkart/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/flipkart/${keyUrl}/${boardPhrase}/filter`;
          }
        } else if (searchType === "REDDIT_ADS") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/reddit/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/reddit/${keyUrl}/${boardPhrase}/filter`;
          }
        } else if (searchType === "DISPLAY_ADS") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/display/url/text/filter`;
        } else if (searchType === "BING_ECOM_ADS") {
          apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/bing-ecom/${keyUrl}/${boardPhrase}`;
          if (selectedCountry.country || selectedSortKey.value) {
            apiUrl = `https://dashboard.adspyder.io/adspyder/api/client/search/bing-ecom/${keyUrl}/${boardPhrase}/filter`;
          }
        }

        // const body = {
        //   page: page,
        //   query: inputKey,
        //   exclude: "amazon",
        //   searchIn: ["title", "content"],
        //   minimumShouldMatch: 0,
        //   ...(selectedCountry.country && {
        //     countries: selectedCountry && [selectedCountry.country], // Make country an array
        //   }),
        //   ...(selectedSortKey.value && {
        //     sort: selectedSortKey && selectedSortKey.value,
        //   }),
        //   startDate: "2020-01-01",
        //   endDate: "2024-10-01",
        // };
        const getCurrentDate = () => {
          const today = new Date();
          const year = today.getFullYear(); // Full year (e.g., 2024)
          const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
          const day = String(today.getDate()).padStart(2, "0"); // Ensure two digits for day

          return `${year}-${month}-${day}`;
        };
        const body = {
          page: page,
          query: inputKey,
          exclude: "alibaba",
          searchIn: ["title", "content"],
          minimumShouldMatch: 0,
          countries: selectedCountry.country ? [selectedCountry.country] : [], // Use ternary to set countries as an array or empty
          sort: selectedSortKey.value || null, // Set sort key or null
          startDate: "2020-01-01",
          endDate: getCurrentDate(), // Use current date in 'yy-mm-dd' format
        };

        try {
          const response = await axios.post(apiUrl, body, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Add content-type explicitly
            },
          });
          data = response.data;
        } catch (error) {
          notification["error"]({
            message: "No results!!",
            placement: "topRight",
          });
        }


        if (data) {
          if (type === "search") {
            setSearchData(data.documents);
          }
          if (type === "loadMore") {
            setSearchData([...searchData, ...data.documents]);
          }
          updateRequestUserQuota("YOUTUBE_ADS", authUser, {
            searchType,
            numResults: data.documents.length,
          });
        } else {
          if (type !== "loadMore") {
            setSearchData([]);
            notification["error"]({
              message: "No results!!",
              placement: "topRight",
            });
          } else {
            notification["error"]({ message: "No more records found!" });
          }
        }

        setLoadingFullScreen(false);
      }
    }
  };

  const fetchDetails = async (id) => {
    setLoadingFullScreen(true);

    const dts = { id: id };

    const data = await getAdSpyderDetail(dts);
    if (data && data.result && data.success === true) {
      setAdsDetails(data);
    } else {
      setAdsDetails(null);
      notification["error"]({
        message: "No result found!!",
        placement: "topRight",
      });
    }

    setLoadingFullScreen(false);
  };

  const handleRemoveFromFav = async (item) => {
    setLoadingFullScreen(true);

    await deleteDoc(doc(Database, "youtubeAdsCollection", item.docRefId));
    const newData = searchData.map((i) => {
      if (i.id === item.id) {
        delete item.docRefId;
        i.isFav = false;
      }
      return i;
    });
    setSearchData(newData);
    notification["success"]({
      message: "Removed from collection!!",
      placement: "topRight",
    });
    setLoadingFullScreen(false);
  };

  const handleAddToFav = async (item) => {
    setLoadingFullScreen(true);

    const dataString = JSON.stringify(item);
    const payload = {
      userId: uid,
      itemId: item.id,
      searchKey: inputKey,
      searchType: searchType,
      createdDate: Timestamp.fromDate(moment().toDate()),
      adsData: dataString,
    };

    const docRef = await addDoc(
      collection(Database, "youtubeAdsCollection"),
      payload
    );

    if (docRef.id) {
      const newData = searchData.map((i) => {
        if (i.id === item.id) {
          i.docRefId = docRef.id;
          i.isFav = true;
        }
        return i;
      });
      setSearchData(newData);
      notification["success"]({
        message: "Added to collection!!",
        placement: "topRight",
      });
    } else {
      alert("SOMETHING WENT WRONG");
    }
    setLoadingFullScreen(false);
  };

  const clearSearch = () => {
    setAdsDetails(null);
  };

  if (adsDetails) {
    return (
      <AdsDetail
        searchType={searchType}
        data={adsDetails}
        handleGoBack={() => clearSearch()}
      />
    );
  }

  if (viewMyCollection) {
    return (
      <MyCollection
        searchType={searchType}
        user={authUser}
        handleGoBack={() => {
          setViewMyCollection(false);
        }}
      />
    );
  }

  // const onClickMenu = (item) => {
  //   setSelectedCountry(item)
  // }

  let placeholder = "Enter keyword";
  if (selectedCallType === "URL") {
    placeholder = "Enter keyword/URL";
  }
  if (searchType === "DISPLAY_ADS") placeholder = "Enter URL";

  const selectedSearchRadio = (value) => {
    setSelectedRadio(value);
  };

  const selectedCallTypeRadio = (value) => {
    setSelectedCallType(value);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <Header>{heading}</Header>
        <BunnyPlayer type={searchType} />
        {urlSearchTypes.includes(searchType) ? (
          <div className="searchRadioButton" style={{ marginTop: "30px" }}>
            <RadioButton
              options={["Keyword", "URL"]}
              value={selectedCallType}
              onChange={selectedCallTypeRadio}
            />
          </div>
        ) : (
          <></>
        )}
        {urlSearchTypes.includes(searchType) ? (
          <Row className="searchfbInterest">
            <div className="search-input-container">
              <input
                type="text"
                className="text-input"
                placeholder={placeholder}
                name="search"
                value={inputKey}
                onChange={(e) => setInputKey(e.target.value)}
              />
              <button
                type="submit"
                style={{ marginLeft: "0px" }}
                className="primary-button search-button"
                onClick={() => findKeywords("search")}
              >
                Search
              </button>
              <button
                className="view-my-collection-button"
                onClick={() => setViewMyCollection(true)}
              >
                View Collection
              </button>
            </div>
          </Row>
        ) : (
          <Row className="searchfbInterest">
            <div className="search-input-container">
              <input
                type="text"
                className="text-input"
                placeholder={placeholder}
                name="search"
                value={inputKey}
                onChange={(e) => setInputKey(e.target.value)}
              />
              {/* {
            accountType === "FREE" ?
                <Popover content={pleaseSubscribe} className="">
                    <button type="submit" className="primary-button search-button not-allowed-button">Search</button>
                </Popover>
                : */}
              <button
                type="submit"
                style={{ marginLeft: "0px" }}
                className="primary-button search-button"
                onClick={() => findKeywords("search")}
              >
                Search
              </button>
              <button
                className=" view-my-collection-button"
                onClick={() => setViewMyCollection(true)}
              >
                View Collection
              </button>
            </div>
          </Row>
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <AdsSpyLocationDropdown
            defaultValue={selectedCountry}
            onChange={(value) => {
              setSelectedCountry(value);
            }}
          />
          <AdsSpySortDropdown
            defaultValue={selectedSortKey}
            onChange={(value) => {
              setSelectedSortKey(value);
            }}
          />
        </div>
        {
          /* urlSearchTypes.includes(searchType) && */
          (selectedCallType === "Keyword" && searchType !== "DISPLAY_ADS") ? (
            <div className="searchRadioButton">
              <RadioButton
                options={["Broad", "Phrase"]}
                value={selectedRadio}
                onChange={selectedSearchRadio}
              />
            </div>
          ) : (
            <></>
          )
        }
        {searchData.length ? (
          <div className="row">
            {searchData.map((item, index) => {
              if (searchType === "YOUTUBE") {
                return (
                  <YoutubeAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                    fetchDetails={fetchDetails}
                  />
                );
              } else if (searchType === "GOOGLE_ECOM") {
                return (
                  <GoogleEcomAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              } else if (searchType === "GOOGLE_ADS") {
                return (
                  <GoogleAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              } else if (searchType === "DISPLAY_ADS") {
                return (
                  <DisplayAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              } else if (searchType === "FLIPKART_ADS") {
                return (
                  <FlipkartAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              } else if (searchType === "REDDIT_ADS") {
                return (
                  <RedditAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              } else if (searchType === "LINKEDIN_ADS") {
                return (
                  <LinkedInAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              } else if (searchType === "AMAZON_ADS") {
                return (
                  <AmazonAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              } else if (searchType === "FACEBOOK_ADS") {
                return (
                  <FacebookAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              } else if (searchType === "BING_ECOM_ADS") {
                return (
                  <BingEcomAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              } else if (searchType === "BING_ADS") {
                return (
                  <BingAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                );
              }

              return null;
            })}
          </div>
        ) : null}
        {searchData.length ? (
          <div className="pagination-wrapper">
            <AntButton
              className="ml-5 mt-2"
              onClick={() => findKeywords("loadMore")}
            >
              Load more results..
            </AntButton>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default withRouter(YoutubeAds);

import React, { useState } from "react";
import styles from "./AdImageAnalyzer.module.css";
import { Header } from "../../styles/styles";
import UplaodIcon from "./upload.svg";
import axios from "axios";

const Page = () => {
  const [fileName, setFileName] = useState("");
  const [imageFile, setImageFile] = useState(null); // State to store the uploaded file
  const [parameters, setParameters] = useState("");
  const [resultImage, setResultImage] = useState(null); // State to store the resulting image
  const [intensity, setIntensity] = useState(0.85);
  const [loading, setLoading] = useState(false);
  const handleIntensityChange = (event) => {
    setIntensity(event.target.value);
  };
  // Handle file change and store the file
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(event.target.files);
    if (file) {
      setFileName(file.name);
      setImageFile(file); // Save the file in state
    }
  };

  // Function to handle the form submission
  const handleSubmit = async () => {
    setLoading(true);
    if (!imageFile) {
      setLoading(false);
      alert("Please upload an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", imageFile);

    try {
      const response = await axios.post(
        "https://furkan14-image-analyser.hf.space/api/v1/analyze/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const analysis_id = response.data.analysis_id;
      setParameters(response.data);
      console.log(response.data);
      const heatmapResponse = await axios.get(
        `https://furkan14-image-analyser.hf.space/api/v1/analyze/${analysis_id}/heatmap?alpha=0.5`,
        { responseType: "arraybuffer" } // Expect binary data for the image
      );

      // Convert binary image data to a URL
      const blob = new Blob([heatmapResponse.data], { type: "image/png" });
      const heatmapUrl = URL.createObjectURL(blob);

      // Set the heatmap URL in state to render on the UI
      setResultImage(heatmapUrl);
      setLoading(false);
      console.log("Heatmap generated successfully:", heatmapUrl);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <div className={styles.adImageAnalyzer}>
      <Header>Ad Image Analyzer</Header>
      <p className={styles.heading}>
        Upload an image and draw regions to analyze patterns and cognitive
        metrics
      </p>
      <div className={styles.uploadContainer}>
        <div className={styles.uploadBox}>
          <input
            type="file"
            id="fileInput"
            className={styles.fileInput}
            onChange={handleFileChange}
            accept="image/png, image/jpeg, image/gif"
          />
          <label htmlFor="fileInput" className={styles.label}>
            <img
              src={UplaodIcon}
              alt="Upload Icon"
              className={styles.uploadIcon}
            />
            <p className={styles.filename}>
              {fileName ? fileName : "Click to upload"}
            </p>
            <p className={styles.fileType}>PNG, JPG or GIF (MAX. 800x400px)</p>
          </label>
        </div>
      </div>
      <div className={styles.heatmapbtn}>
        {loading ? (
          <button onClick={handleSubmit} disabled style={{cursor:"not-allowed",backgroundColor:"#659dff" }}>Loading...</button>
        ) : (
          <button onClick={handleSubmit}>Generate Heat Map</button>
        )}
      </div>
      {/* heat map result  */}
      {resultImage && (
        <div className={styles.container}>
          <div className={styles.heatmapSection}>
            <h2>Attention Heatmap</h2>
            <div className={styles.sliderContainer}>
              <label htmlFor="heatmapIntensity">Heatmap Intensity</label>
              <input
                type="range"
                id="heatmapIntensity"
                min="0.0"
                max="1.0"
                step="0.01"
                value={intensity}
                onChange={handleIntensityChange}
                className={styles.slider}
              />
              <span>{intensity}</span>
            </div>
            <div className={styles.heatmap}>
              <img
                src={resultImage}
                alt="Attention Heatmap"
                style={{ opacity: intensity }}
                className={styles.heatmapImage}
              />
            </div>
          </div>
          <div className={styles.metricsSection}>
            <h3>Overall Image Metrics</h3>
            <div className={styles.metrics}>
              <div>
              <p>Cognitive Demand</p>
                <span>{parameters.metrics.cognitive_demand.toFixed(2)}%</span>

              </div>
              <div>
                <p>Clarity Score</p>
                <span>{parameters.metrics.clarity_score.toFixed(2)}%</span>
              </div>
              <div>
                <p>Performance Score</p>
                <span>{parameters.metrics.focus_score.toFixed(2)}%</span>
              </div>
              <div>
                <p>Engagement Score</p>
                <span>{parameters.metrics.engagement_score.toFixed(2)}%</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Popconfirm, Button, notification, Table, Form, Input } from 'antd';
import { collection, query, getDocs, doc, deleteDoc, addDoc, Timestamp } from "firebase/firestore";
import { Database } from '../../../firebase';
import { PAGE_HEAD_TITLE } from "../../../constants";
import { Header } from '../../../styles/styles'

const ListNotifications = (props) => {

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        document.title = `Notifications - Admin ${PAGE_HEAD_TITLE}`;
        (async function () {
            await fetchNotifications();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchNotifications = async () => {
        const q = query(collection(Database, "notifications"));
        const querySnapshot = await getDocs(q);

        setNotifications(
            querySnapshot.docs.map(doc => {
                const data = doc.data();
                const dateCreated = moment(data.createdDate.toDate()).format('DD-MM-YYYY')
                return ({
                    title: data.title,
                    description: data.description,
                    createdDate: dateCreated,
                    key: doc.id,
                    id: doc.id,
                })
            })
        );
    }

    const deleteCollection = async (id) => {
        await deleteDoc(doc(Database, "notifications", id));
        await fetchNotifications();
        notification['success']({
            message: 'Notifications has been deleted.',
        });
    }

    const onFinish = async (values) => {
        const docRef = await addDoc(collection(Database, "notifications"), {
            title: values.title,
            description: values.description,
            createdDate: Timestamp.fromDate(moment().toDate()),
        });

        if (docRef.id) {
            await fetchNotifications();
        } else {
            alert("SOMETHING WENT WRONG");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <React.Fragment>
                    {/* <Button type="link" >
                        <Link to={'/keywords-search-collection/' + record.id}>View</Link>
                    </Button> */}
                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => deleteCollection(record.id)}>
                        <Button type="link" danger>Delete</Button>
                    </Popconfirm>
                </React.Fragment>
            ),
        },
    ];

    return (
        <div className="content-wrapper">
            <Header>Notifications</Header>
            <div className="stat-box-wrapper">
                <Form
                    name="basic"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    // initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{ required: true, message: 'Please enter title!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{ required: true, message: 'Please enter description!' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 4, span: 20 }} style={{ marginBottom: 0 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div className="cust-table" style={{ marginTop: '30px' }}>
                <Table columns={columns} dataSource={notifications} />
            </div>
        </div>
    )
}

export default ListNotifications;

import axios from "axios";
import { FACEBOOK_API_DATA_LIMIT, FIREBASE_URL_FACEBOOK } from "../constants";

export const getFacebookHiddenInterstApi = async (searchQuery) => {
  let responseData = [];

  try {
    const response = await axios.post(
      FIREBASE_URL_FACEBOOK,
      {
        searchQuery: searchQuery,
        apiDataLimit: FACEBOOK_API_DATA_LIMIT,
      },
      {
        headers: { Accept: "application/json" },
      }
    );
    console.log("res is", response);

    const data = response?.data?.data;

    if (data && data.length) {
      responseData = data.map((item) => ({
        name: item.name,
        topic: item.topic,
        audienceSizeLowerBound: item.audience_size_lower_bound,
        audienceSizeUpperBound: item.audience_size_upper_bound,
      }));
    }
  } catch (error) {
    console.error("Error fetching Facebook hidden interests:", error);
  }

  return responseData;
};
